// eslint-disable-next-line import/no-named-as-default
import Icon from "@mdi/react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { mdiAlert, mdiArrowDown, mdiArrowUp, mdiContentCopy, mdiHistory } from "@mdi/js";
import { Alert, Button, Dropdown, Popover, Select } from "antd";
import { setSelectedWells } from "store/features";
import styled from "styled-components";

import { Uwi } from "models";

import { SubHeading, Tooltip } from "components/base";

import { setPreviouslySelectedWells, setSelectedWell } from "./context";

export const TypeLogWellSelector = ({ state, dispatch }) => {
  // state
  const [showCopySuccessMessage, setShowCopySuccessMessage] = useState(false);
  const [warning, setWarning] = useState("");
  const stateDispatch = useDispatch();

  const selectedWell = state?.selectedWell;
  const wells = state?.wells;

  const selectedIndex = wells
    ? wells.findIndex((well) => well.id === selectedWell?.Uwi)
    : -1;
  const previousWell = selectedIndex > 0 ? wells[selectedIndex - 1] : null;
  const nextWell = selectedIndex < wells?.length ? wells[selectedIndex + 1] : null;

  const copyToClipboard = useCallback(() => {
    const text =
      state?.uwidFormat === "entityName" ? selectedWell.FormattedUwi : selectedWell.Uwi;
    navigator.clipboard.writeText(text).then(
      function () {
        setShowCopySuccessMessage(true);
      },
      function () {
        // eslint-disable-next-line no-console
        console.error("Could not copy text: ", text);
      }
    );
  }, [selectedWell, state?.uwidFormat]);

  useEffect(() => {
    if (showCopySuccessMessage) {
      setTimeout(() => {
        setShowCopySuccessMessage(false);
      }, 2000);
    }
  }, [showCopySuccessMessage]);

  useEffect(() => {
    setWarning(
      selectedWell && selectedIndex < 0 ? `Selected well is not in the list` : ""
    );
  }, [selectedWell, selectedIndex]);

  const updateSelectedWell = (uwid: string) => {
    if (uwid) {
      const formattedUwid = new Uwi().toFormatted(uwid);
      const obj = {
        Uwi: uwid,
        FormattedUwi: formattedUwid
      };
      setSelectedWell(dispatch, obj);
      setPreviouslySelectedWells(dispatch, obj);
      const well = {};
      well[uwid] = { Uwi: uwid };
      stateDispatch(setSelectedWells(well));
    }
  };

  return (
    <Wrapper>
      <HeaderContainer>
        <TitleWrapper>
          <SubHeading>Selected Well</SubHeading>
        </TitleWrapper>
        <ActionContainer>
          {state?.previouslySelectedWells?.length > 0 && (
            <Tooltip title="Previously Selected Wells">
              <Dropdown
                overlayClassName="modal-fixed-font-width"
                menu={{
                  onClick: (v) => updateSelectedWell(v.key),
                  selectedKeys: ["entityName"],
                  items: state.previouslySelectedWells?.map((well) => {
                    return {
                      key: state?.uwidFormat === "uwid" ? well?.Uwi : well?.FormattedUwi,
                      label: state?.uwidFormat === "uwid" ? well?.Uwi : well?.FormattedUwi
                    };
                  })
                }}
                trigger={["click"]}>
                <Button
                  data-testid="prev-selected-well-button"
                  icon={
                    <Icon path={mdiHistory} className="activity-action-icon" size={1} />
                  }
                />
              </Dropdown>
            </Tooltip>
          )}
          <Tooltip title={`Select Previous ${state.entityKind}`}>
            <Button
              data-testid="prev-well-button"
              onClick={() => updateSelectedWell(previousWell?.id)}>
              <Icon path={mdiArrowUp} className="activity-action-icon" size={1} />
            </Button>
          </Tooltip>
          <Tooltip title={`Select Next ${state.entityKind}`}>
            <Button
              data-testid="next-well-button"
              onClick={() => updateSelectedWell(nextWell?.id)}>
              <Icon path={mdiArrowDown} className="activity-action-icon" size={1} />
            </Button>
          </Tooltip>
          <Tooltip title={`Copy Selected ${state.entityKind}`}>
            <Popover
              open={showCopySuccessMessage}
              trigger="click"
              overlayClassName="overlay-no-padding"
              content={<Alert type="success" message="Copied to Clipboard!" />}>
              <Button data-testid="copy-well-button" onClick={copyToClipboard}>
                <Icon path={mdiContentCopy} className="activity-action-icon" size={1} />
              </Button>
            </Popover>
          </Tooltip>
        </ActionContainer>
      </HeaderContainer>
      <InputWrapper>
        <SelectWrapper
          showSearch
          placeholder={`No Well Selected`}
          popupClassName="modal-fixed-font-width"
          value={selectedWell?.Uwi}
          onChange={updateSelectedWell}
          filterOption={(inputValue, option) => {
            if (inputValue && option) {
              return (
                option.entityName?.toLowerCase()?.includes(inputValue.toLowerCase()) ||
                option.uwid?.toLowerCase()?.includes(inputValue.toLowerCase())
              );
            }
            return true;
          }}
          options={wells?.map((well) => {
            return {
              key: well?.id,
              label: state?.uwidFormat === "uwid" ? well?.id : well?.value,
              entityName: well?.label,
              uwid: well.id,
              value: well?.id
            };
          })}
        />

        {warning && (
          <WarningContainer>
            <Icon path={mdiAlert} className="activity-action-icon" size={1} /> {warning}
          </WarningContainer>
        )}
      </InputWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0;
  display: grid;
  grid-gap: 5px;
  place-items: start;
  grid-template-rows: auto minmax(0, 1fr);
  grid-template-columns: minmax(0, 1fr);
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
  color: var(--color-text);
  width: 100%;
  padding: 8px 10px 8px 20px;
  border-bottom: 1px solid #e2e5e6;

  h3 {
    margin: 0;
    margin-right: auto;
  }

  .label {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 8px;
    align-items: center;
    color: var(--lead);
  }

  .icon {
    place-self: center;
  }

  .BaseIcon {
    color: var(--fume);
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const InputWrapper = styled.div`
  width: 100%;
  padding: 8px 15px;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;

  .ant-btn {
    color: #a2aaad;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 6px;
  }

  .ant-btn:hover {
    color: var(--color-primary);
  }
`;

const SelectWrapper = styled(Select)`
  font-family: var(--fontMono);
  width: 100%;
`;

const WarningContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  padding-top: 4px;
  color: var(--orange);
  font-weight: var(--fontWeightMedium);
`;
