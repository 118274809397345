import { useEffect, useState } from "react";

import { Select, Slider } from "antd";
import styled from "styled-components";

import { ThreeDViewerOptionT } from "models";

import getGeoMapLayers from "components/geo-map/hooks/getGeoMapLayers";

function ThreeDVSettingsOptions(props: ThreeDViewerOptionT): JSX.Element {
  const { data: mapLayers } = getGeoMapLayers();

  const mcdanielGroup = [
    {
      label: "McDaniel",
      options: [{ label: "McDaniel", value: "McDaniel" }]
    }
  ];
  const [modelSources, setModelSources] = useState(mcdanielGroup);

  useEffect(() => {
    const orgGroups = {
      label: "Organization",
      options: []
    };
    if (mapLayers?.length > 0) {
      for (const item of mapLayers) {
        if (
          orgGroups.options.findIndex((o) => o.value === item.group) === -1 &&
          item.hasLsdMap
        ) {
          orgGroups.options.push({ label: item.group, value: item.group });
        }
      }
    }
    setModelSources([...mcdanielGroup, orgGroups]);
  }, [mapLayers]);

  return (
    <RootContainer>
      <OptionItem>
        <label>Scale Z</label>
        <Slider
          className="action"
          value={props.scaleZ}
          min={1}
          max={40}
          onChange={(e) => {
            props.onOptionChange && props.onOptionChange({ scaleZ: e }, false);
          }}
        />
      </OptionItem>
      <OptionItem>
        <label>Line Width</label>
        <Slider
          className="action"
          value={props.lineWidth}
          min={1}
          max={10}
          onChange={(e) => {
            props.onOptionChange && props.onOptionChange({ lineWidth: e }, false);
          }}
        />
      </OptionItem>
      <OptionItem>
        <label>Tops Model Source</label>
        <Select
          size="small"
          value={props.modelSource}
          options={modelSources}
          onChange={(e) => {
            props.onOptionChange && props.onOptionChange({ modelSource: e }, true);
          }}
        />
      </OptionItem>
    </RootContainer>
  );
}

export default ThreeDVSettingsOptions;

const RootContainer = styled.div`
  min-width: 280px;
  border-radius: var(--border-radius);

  .ant-card-body {
    padding: 5px;
  }

  .ant-select {
    min-width: 180px;
    width: 180px;
    align-self: flex-end;
  }
`;

const OptionItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3px 0;

  .ant-slider {
    width: 80px;
    margin: 5px 0;
  }

  .ant-select {
    min-width: 140px;
  }

  .ant-input {
    height: 25px;
    max-width: 80px;
  }
`;
