import * as mapLayers from "constants/mapLayers.constants";

export const DEFAULT_CHECKED_LAYERS = [
  mapLayers.ATTENTION_POLYGON_LAYER,
  mapLayers.ATTENTION_WELL_LAYER,
  mapLayers.BASE_WELL_LAYER,
  mapLayers.COMPLETION_FRAC_LAYER,
  mapLayers.COMPLETION_PERF_LAYER,
  mapLayers.EXPLOITED_LAYER,
  mapLayers.HIGHLIGHTED_PLAY_LAYER,
  mapLayers.HIGHLIGHTED_WELL_LAYER,
  mapLayers.HIGHLIGHTED_WELL_POINT_LAYER,
  mapLayers.IPDB_LAYER,
  mapLayers.SECTION_LABEL_LAYER,
  mapLayers.SECTION_LAYER,
  mapLayers.SELECTED_SINGLE_WELL_LAYER,
  mapLayers.SELECTED_WELL_LAYER,
  mapLayers.SELECTED_WELL_POINT_LAYER,
  mapLayers.SHAPEFILE_LABELS_LAYER,
  mapLayers.SYNC_WELL_LAYER,
  mapLayers.TOWNSHIP_LABEL_LAYER,
  mapLayers.TOWNSHIP_LAYER,
  mapLayers.NTS_BLOCK_LABEL_LAYER,
  mapLayers.UNEXPLOITED_LAYER,
  mapLayers.WELL_LABEL_LAYER,
  mapLayers.WELL_LAYER_POINT,
  mapLayers.TYPE_LOG_LAYER,
  mapLayers.TYPE_LOG_VERTICAL_LAYER,
  mapLayers.FACILITY_LAYER,
  mapLayers.FACILITY_LABEL_LAYER,
  mapLayers.WELL_LAYER,
  mapLayers.XDA_INTERCEPT_LAYER,
  mapLayers.XDA_LABEL,
  mapLayers.XDA_WELL_INTERCEPT_POINT
];

export const DEFAULT_EXPANDED_TREE_KEYS: string[] = ["Project Shapefiles"];
